import React from 'react';
import NavBar from '../component/navBar'
import Slider from '../component/slider'
import About from '../component/about';
import Courses from '../component/courses';
import Footer from '../component/footer';
import Gallery from "../component/gallery"

const Home =()=>{
    return(
        <>
        <NavBar />
        <Slider />
        <About/>
        <Courses/>
        <Gallery/>
        <Footer />
       
        </>
    )
}

export default Home