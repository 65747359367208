// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth"
import {getFirestore} from "firebase/firestore"
import { getStorage} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAu9dVzlzrs59-xRMLLok3JUXz7ymrh1_c",
  authDomain: "accountexpress-1c6cc.firebaseapp.com",
  projectId: "accountexpress-1c6cc",
  storageBucket: "accountexpress-1c6cc.appspot.com",
  messagingSenderId: "646166785307",
  appId: "1:646166785307:web:c3db18d467c7e9bc6437be",
  measurementId: "G-6RP0Y4805D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth()
const db = getFirestore()
const storage = getStorage(app)
export {app, auth, db, storage}
