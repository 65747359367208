export const images = [
    {
        id:new Date(),
        logo:"A",
        text:"Accounts",
        description:"An account is a detailed record of all the money that a business or a person receives and spends."
        
    },
    {
        id:new Date(),
        logo:"B",
        text:"Busy Software",
        description:"Manage your business seamlessly with BUSY, it is a powerful GST Accounting Software that keeps you in control of your business completely."

    },
    {
        id:new Date(),
        logo:"G",
        text:"GST",
        description:"Attend GST Practitioner Course by GST Experts & Resolve Daily GST Issues. Become expert in , Invoicing, Software"
    },
    {
        id:new Date(),
        logo:"T",
        text:"Tally",
        description:"Tally is the most popular and reliable GST ready accounting software amongst businesses in India. TallyPrime ensures you generate GST invoices and transactions as per the GST format."

    },
    {
        id:new Date(),
        logo:"B",
        text:"Basic Computer & Typing",
        description:"The objective of the course is to impart basic level computer appreciation programme with more emphasis on hands on training."

    },
    {
        id:new Date(),
        logo:"P",
        text:"Personality Development",
        description:"Personality Development teaches us how to communicate and display ourselves as having caring skills."

    },
    {
        id:new Date(),
        logo:"More",
        text:"Other Coureses",
        description:"Get an engaging, interactive, user-friendly and robust website solution representing your business in online marketplace with us"

    },
   
]

export const gallery =[
    {
        img:"/images/gallery-img1.jpeg"
    },
    {
        img:"/images/gallery-img2.jpeg"
    },
    {
        img:"/images/gallery-img3.jpeg"
    },
    {
        img:"/images/gallery-img4.jpeg"
    },
]