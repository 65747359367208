import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import {CssBaseline, Grid, TextField, Box,Typography, Container} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {Formik, Form} from 'formik';
import * as yup from 'yup';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {name, email, phoneRegExp, num} from '../helpers/validation';
import NavBar from "./navBar";
import { formStyles } from "../styles/verifi";
import {formRegister} from "../actions/form";
import Loader from '../component/common/loader'

const RegistrationForm = () => {
    const {loader} = useSelector(({registerForm})=>registerForm)
    const classes = formStyles()

  const dispatch = useDispatch();
  const theme = createTheme();
  const initialValues = {
    firstName:"",
    lastName:"",
    email:"",
    phoneNumber:"",
    dob: "",
    address:"",
   }

   const validation = yup.object({
    firstName:yup.string()
    .required("Please Enter First Name")
    .matches(name, "Enter valid name"),
    email:yup.string()
    .required("Please Enter Email Address")
    .matches(email, "Enter valid Email address"),
    phoneNumber:yup.string()
    .required("Please enter valid phone Number")
    .matches(phoneRegExp, "Enter valid phone number"),
    dob:yup.date()
    .required("Please Enter Date of birth")
    .max(new Date(Date.now() - 567648000000), "You must be at least 18 years"),
    address:yup.string()
    .required("Please enter Address"),

   })
   const handleSubmit =(values,{resetForm})=>{
      dispatch(formRegister(values))
      resetForm({values:""})
   }
 
  return (
    <>   
        <NavBar/>   
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >           
            <Typography component="h1" variant="h5" style={{marginBottom:"10px", fontSize:27}}>
            Registration Form
            </Typography>
            <Box noValidate sx={{ mt: 1 }}>
            <Formik 
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit= {handleSubmit}              
                >{({handleChange, values, errors, touched, handleBlur})=>(
                <Form id="kt_account_profile_details_form" className="form">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="off"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onChange={handleChange}
                    value ={values.firstName}
                    onBlur ={handleBlur}
                    />
                    <div className ={classes.showError}>
                    { touched.firstName && errors.firstName ? <div className ={classes.error}>{errors.firstName}</div>: ""}
                    </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="off"
                  onChange={handleChange}
                  value ={values.lastName}
                  onBlur ={handleBlur}     
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="off"
                  onChange={handleChange}
                  value ={values.email}
                  onBlur ={handleBlur}                  
                  />
                  <div className ={classes.showError}>
                  { touched.email && errors.email ? <div className ={classes.error}>{errors.email}</div>: ""}
                  </div>
                
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="dob"
                  label="Date of birth"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  autoComplete="off"
                  onChange={handleChange}
                  value ={values.dob || ""}
                  onBlur ={handleBlur}                   
                  />
                  <div className ={classes.showError}>
                  { touched.dob && errors.dob ? <div className ={classes.error}>{errors.dob}</div>: ""}
                  </div>
                
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Phone Number"
                  variant ="outlined"                   
                  type="text"
                  name="phoneNumber"
                  autoComplete="off"
                  onChange={handleChange}
                  value ={values.phoneNumber}
                  onBlur ={handleBlur}                    
                  />
                  <div className ={classes.showError}>
                  { touched.phoneNumber && errors.phoneNumber ? <div className ={classes.error}>{errors.phoneNumber}</div>: ""}
                  </div>
                
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Address"
                  variant ="outlined"
                  type="text"
                  name="address"
                  autoComplete="off"
                  multiline
                  rows={4}
                  onChange={handleChange}
                  value ={values.address}
                  onBlur ={handleBlur}                   
                  />
                  <div className ={classes.showError}>
                  { touched.address && errors.address ? <div className ={classes.error}>{errors.address}</div>: ""}
                  </div>                
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              style={{backgroundColor:"#120F11", color:"#fff"}}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
             {loader ? <Loader size={25}/>:"Submit"}
            </Button>

            </Form>
            )}
            </Formik>
            </Box>

          </Box>

          {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
        </Container>
      </ThemeProvider>
    </>
  );
};

export default RegistrationForm
