import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import {Link} from "react-router-dom"
import { Grid, Box, Toolbar, Dialog, DialogContent, DialogTitle, AppBar, IconButton } from "@mui/material";
import Paper from "@mui/material/Paper";
import { fetchDocument, setDocumentid, deleteDocument } from "../actions/document";
import Header from "./header";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import swal from "sweetalert"

const mdTheme = createTheme();

export default function Documents() {
  const [open, setOpen] = useState(false);

  const { allDocs, loader , documentId} = useSelector(({ document }) => document);
  console.log("dfgfdg", documentId, allDocs)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDocument());
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleView =(val)=>{
    dispatch(setDocumentid(val))
    setOpen(true)
    console.log(val)
  }

  const handleDelete =async(val)=>{
  console.log(val)
  try{
    const willDelete = await swal({
      title:"Are you sure?",
      text:`Certificate No "${val?.data?.certificateNo}" will be permanently deleted`,
      icon:"warning",
      buttons:true,
      dangerMode:true,
    });
    if(willDelete){
      const data = dispatch(deleteDocument(val.docId))
      if(data){
        await swal({
          title:"Delete",
          text:"Document deleted successfully",
          icon:"success",
          timer:2000,
          button:false
        })
      }
    }
  }
  catch(err){
    swal({title:"Error", text:err.message, icon:"warning", timer:2000, button:false})
  }
  }
  return (
    <>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <Header />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <h4>Documents</h4>
              <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <div className="table-responsive">
                      <h4>Total: {allDocs?.length}</h4>
                      <table
                        className="table table-hover table-row-dashed align-middle gs-0 gy-4 my-0"
                        style={{ border: "none" }}
                      >
                        <thead style={{ color: "#ffff" }}>
                          <tr
                            className="fs-7 fw-bold border-bottom-0"
                            style={{ fontSize: "15px", color: "#838383" }}
                          >
                            <th>Certificate No</th>
                            <th>certificate</th>
                            <th>View Detail</th>
                            {/* <th>Edit</th> */}
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                allDocs?.length && allDocs?.map((val,index)=>
                  <tr key={index} className ="table-div">
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column">
                        <span
                          href="#" className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                          style={{padding: "5px"}}
                          // className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                        >
                          {val?.data?.certificateNo}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                  <span
                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    
                  <img src ={val?.data?.certificate} alt="certificate1" height="50" width="50" zoom={true}/>
                  </span>
                  </td>
                  <td >
                  <span
                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                    style={{cursor:"pointer"}}
                  >
                  <IconButton onClick={()=>handleView(val)}><VisibilityOutlinedIcon  color="action"/></IconButton>
                  </span>
                  </td>
                  <Dialog
                      style={{padding:2}}
                      fullScreen
                      onClose={handleClose}
                      aria-labelledby="customized-dialog-title"
                      open={open}
                  >
                     <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>
                      </Toolbar>
                    </AppBar>
                    <DialogTitle>
                    Certificate details
                    </DialogTitle>
                    <DialogContent dividers>
                    <div className="book-display">
                      {/* <img src={book.coverImage} alt={book.title} /> */}
                      <div className="book-info">
                        <h4>Certificate No</h4>                     
                        <>
                         <h5>{documentId?.data?.certificateNo}</h5>
                        {/* <p>{auth.description}</p>                         */}
                        </>                        
                        <hr />
                        
                        <h4>certificate</h4>
                      <img src={documentId?.data?.certificate} alt="certificate" />
                      <hr />
                                                                                                            
                        <hr />
                        {/* <h5>{book.chapterName}</h5> */}
                      </div>
                      </div>
                 
                    </DialogContent>                                                                                   
                  </Dialog>
                  {/* <td>
                  <span
                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                    style={{cursor:"pointer"}}
                  >
                    <IconButton><EditIcon color="action" /></IconButton>
                  </span>
                  </td> */}
                  <td>
                  <span
                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                    style={{cursor:"pointer"}}
                  >
                    <IconButton onClick={()=>handleDelete(val)}><DeleteIcon color="action" /></IconButton>
                  </span>
                  </td>
                  </tr>
                  )}
                        </tbody>
                      </table>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}
