import  React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import {Grid,Box, Toolbar} from '@mui/material';
import Paper from '@mui/material/Paper';
import {getContactDetails} from "../actions/contact";
import Header from './header';
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";

const mdTheme = createTheme();

export default function ContactForm() {
    const {contactForm, loader} = useSelector(({contact})=>contact)
    const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(getContactDetails())
  },[])

  return (
    <>
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <Header />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* Chart */}
              <Grid item xs={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: "100%",
                  }}
                >
            <div className="table-responsive">
            <table className="table table-hover table-row-dashed align-middle gs-0 gy-4 my-0" style={{border:"none"}}>
              <thead style={{color: "#ffff"}}>
                <tr className="fs-7 fw-bold border-bottom-0" style={{fontSize: "15px", color:"#838383"}}>          
                    <th>Name</th>
                    <th>Email</th>
                    <th>Message</th>              
                </tr>
              </thead>
              <tbody>
                {
                contactForm?.length && contactForm?.map((val,index)=>
                  <tr key={index} className ="table-div">
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column">
                        <span
                          href="#" className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                          style={{padding: "5px"}}
                          // className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                        >
                          {val?.name}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                  <span
                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                  {val?.email}
                  </span>
                  </td>
                  <td>
                  <span
                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                  {val?.message}
                  </span>
                  </td>
                  </tr>
                  )}
                  </tbody>
                  </table>
                  </div>                  
                  </Paper>
                  </Grid>
                  </Grid>     
               </Container>
               </Box>
               </Box>
               </ThemeProvider>
               </>
  );
}

