import {makeStyles} from "@material-ui/core"
import { containerClasses } from "@mui/system"
import { NavLink as Link } from 'react-router-dom';
import styled from '@emotion/styled';
 
export const NavLink = styled(Link)`
  color: #808080;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &.active {
    color: #131921;
  };
  
`;

export const NavMenu = styled.div`
  display: flex;
 
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export const  navStyles = makeStyles((theme)=>({

    header:{
        padding:"0 2.8rem",
        height:"5rem",
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        backgroundColor:"whitesmoke"
    },
    logo:{
        height:"7rem",
        width:"8rem"
    },
    navlist:{
        display:"flex",
        marginTop:"10px",
        listStyle:"none",
    [theme.breakpoints.down("md")]:{
        display:"none"
    }
},
mobileView:{
    display:"none",
    [theme.breakpoints.down("md")]:{
        display:"block",
        left:40,
        padding:2,
        height:40,
        border:"1px solid gray"
    }
},
   about:{
        padding:"10px 15px",
        marginTop:20,
    },

    
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // color: "black",
      },
      paper: {
        width: 400,
        backgroundColor: "#4B4A4A",
        color: "white",
        borderRadius: 10,
      },
     
   
    
}))