import {  Routes, Route, Navigate } from "react-router-dom";
import RegistrationForm from '../component/form';
import Verification from '../component/verification';
import DashboardContent from '../admin/dashboard';
import SignIn from '../admin/login';
import Home from "../pages/home";
import {useSelector} from 'react-redux'
import NotFound from '../404';
import RegistrationFormDetail from "../admin/registrationFormdetail";
import ContactForm from "../admin/contactForm";
import Documents from "../admin/documents"
// import SignUp from '../admin/signup'
import Contact from "../component/contact";
import Header from "../admin/header"

const AppRoute = ()=>{
    const {user, isLoggedIn} = useSelector(({user})=>user)
    const auth = user.accessToken
  
    return (
                <Routes>
                <Route path="/" element={<Home />} />
            <Route path="register" element={<RegistrationForm />} />
            <Route path="verification" element={<Verification />} />
            {/* <Route path="signup" element = {<SignUp />} /> */}
            <Route path="contact" element = {<Contact />} />

        {
          auth?
          <>
          <Route path ="/dashboard" element={<RegistrationFormDetail />}/>
          <Route path ="/upload" element={<DashboardContent />}/>
          <Route path ="/contactDetails" element={<ContactForm />}/>
          <Route path ="/document" element ={<Documents/>} />

          </>
           :
          <>
          <Route exact path="/login" element={<SignIn />} />   
          <Route path="*" element={<Navigate to="/login" />}/>
          </>  
        }
  
            {/* <Route path="*" element={<Navigate to="/" />} /> */}
       </Routes>        
    );
}

export default AppRoute