import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import NavBar from "./navBar";
import { createContact } from "../actions/contact";
import Loader from '../component/common/loader';

const Contact = () => {
    const {loader} = useSelector(({contact})=>contact)
  const dispatch = useDispatch();
  const theme = createTheme();
  const [data, setData] = useState({
    name:"",
    email:"",
    msg:""
})

const handleChange=(e)=>{
    const {name, value} = e.target
    setData({...data, [name]:value})
}

const handleSubmit = (e)=>{
    e.preventDefault()
    const {name, email, msg} = data
    if(name === "" || email === "" || msg === "") return;
    dispatch(createContact(data))
    clear();
}
  
  
const clear = ()=>{
    setData({
        name:"",
        email:"",
        msg:""
    })
}
  return (
    <>   
        <NavBar/>
        <marquee style={{ color: 'black', fontSize: '1em', marginTop:"5px" }}><h5>
        Feel free to reach us at texpress041@gmail.com & call us on :- 75024-92222, 99882-99482</h5></marquee>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            
            <Typography component="h1" variant="h5">
              Contact Us
            </Typography>

            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                onChange={handleChange}
                value={data.name}
                autoComplete="off"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="email"
                label="Email"
                type="email"
                id="email"
                onChange={handleChange}
                value={data.email}
                autoComplete="off"
              />
             <TextField
                margin="normal"
                multiline
                rows={5}
                required
                fullWidth
                name="msg"
                label="Message"
                onChange={handleChange}
                value={data.msg}
                autoComplete="off"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                style={{backgroundColor:"#120F11", color:"#fff"}}
                sx={{ mt: 3, mb: 2 }}
              >
                {loader ? <Loader size={25}/>:"Submit"}
              </Button>
            </Box>
          </Box>

          {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
        </Container>
      </ThemeProvider>
    </>
  );
};

export default Contact;
