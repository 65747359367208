import React from 'react';
import { gallery } from '../data/data';
import { makeStyles, Typography } from "@material-ui/core";


const useStyles = makeStyles((theme)=>({
    image:{
        display: "flex",
        justifyContent:"center",
        flexWrap: "wrap",
        gap: "25px",
        padding:"10px 55px",       
        [theme.breakpoints.down("sm")]:{
            gap: "15px",
            display:"flex",
            justifyContent:"center",
        }
    },
    card:{
        width: "275px",
        display: "flex",
        justifyContent:"column", 
    },
    card_image :{        
        maxWidth: "270px",
        borderRadius: "9px",
        marginBottom: "9px",
        [theme.breakpoints.down("md")]:{
            maxWidth: "250px",
        }
    }
}))

const Gallery =()=>{
    const classes = useStyles()
    return(
        <>
        <Typography variant ="h4" style={{textAlign:"center"}}>Gallery</Typography>
        <div className={classes.image}>
        {
            gallery.map((image, i)=>
            <div className ={classes.card} key={i}>
                <img src={image.img} alt="image" className={classes.card_image}/>
             </div>
            )
        }
        </div>        
        </>
    )
}

export default Gallery