import React, {useState, useEffect} from 'react';
import { PersistGate } from "redux-persist/integration/react";
import AppRoute from './routes'
import { BrowserRouter} from "react-router-dom";
import {store, persistor} from "./store";
import { Provider } from "react-redux";

function App() {


  return (
    <PersistGate persistor={persistor}>
    <Provider store={store}>
      <BrowserRouter>
        <AppRoute />
      </BrowserRouter>
    </Provider>
  </PersistGate>
  );
}

export default App;
