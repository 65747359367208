import { makeStyles } from "@material-ui/core";

export const commonStyle = makeStyles((theme) => ({
  loaderV: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const loaderStyle = { color: "white" };
