import React, {useState, useEffect} from 'react';
import Backdrop from "@mui/material/Backdrop";
import Login from '../component/login'
import { Typography, Button } from "@material-ui/core"
import { navStyles } from "../styles/navBar"
import SearchIcon from '@mui/icons-material/Search';
import { NavLink, NavMenu} from "../styles/navBar";
import Modal from "@mui/material/Modal";
import {Link, Navigate, useNavigate} from "react-router-dom"
import Fade from "@mui/material/Fade";
import { IconButton, AppBar, Tabs, Tab } from "@mui/material";
import SignIn from '../admin/login';
import {useSelector} from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';



const NavBar =()=>{
    const classes = navStyles()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

   
    const navigate = useNavigate()

  const [value, setValue] = React.useState(0);
  const {loader, user} = useSelector(({user})=>user)
  const auth = user.accessToken

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

    return(
      <>
       <div className ={classes.header}>
      <img src = "/images/logo.png" className ={classes.logo} />
      <ul className={classes.navlist}>
      <li className="nav-item active">
                <NavLink to='/' className="nav-link">
                  Home
                </NavLink>                
                </li>
                <li className="nav-item">
                <NavLink to='/register' className="nav-link">
                  Form
                </NavLink>
                </li>
                <li className="nav-item">
                <NavLink to='/verification' className="nav-link">
                  Verification      
                  </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to='/contact' className="nav-link">
                    Contact Us      
                    </NavLink> 
                  </li>
                  <li className="nav-item">
                    <NavLink to={auth ? "/dashboard" : "/login"}>Admin Login</NavLink>
                  </li>
        </ul>
        <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={classes.mobileView}
      >
        <MenuIcon sx={{width:50, height:30}}/>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
       <MenuItem onClick={handleClose}>
        <NavLink to='/' className="nav-link">
            Home
          </NavLink> 
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink to='/register' className="nav-link">
            Form
        </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
        <NavLink to='/verification' className="nav-link">
          Verification      
        </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
        <NavLink to='/contact' className="nav-link">
          Contact Us      
        </NavLink> 
        </MenuItem>
        <MenuItem>
        <NavLink to={auth ? "/dashboard" : "/login"} >Admin Login</NavLink>
        </MenuItem>
      </Menu> 
        {/* <MenuItem onClick={handleClose}>
        <NavLink to='/' className="nav-link">
            Home
          </NavLink> 
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink to='/register' className="nav-link">
            Form
        </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
        <NavLink to='/verification' className="nav-link">
          Verification      
        </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
        <NavLink to='/contact' className="nav-link">
          Contact Us      
        </NavLink> 
        </MenuItem>
        <MenuItem>
        <NavLink to={auth ? "/dashboard" : "/login"}>Admin Login</NavLink>
        </MenuItem> */}
      {/* </Menu> */}
            {/* <div className="collapse navbar-collapse " id="navbarSupportedContent" style={{display:"flex", justifyContent:"flex-end"}}>
                <div className ={classes.menuItem}>
                <ul className="navbar-nav mr-auto ">
                <li className="nav-item active">
                <NavLink to='/' className="nav-link">
                  Home
                </NavLink>                
                </li>
                <li className="nav-item">
                <NavLink to='/register' className="nav-link">
                  Form
                </NavLink>
                </li>
                <li className="nav-item">
                <NavLink to='/verification' className="nav-link">
                  Verification      
                  </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to='/contact' className="nav-link">
                    Contact Us      
                    </NavLink> 
                  </li>
                  <li className="nav-item">
                    <NavLink to={auth ? "/dashboard" : "/login"}>Admin Login</NavLink>
                  </li>
                </ul></div> */}
            {/* </div> */}
        </div>
        
        {/* <div className ={classes.container}>
            <div className={classes.wrapper}>
            <div className={classes.left}>
                <img src = "/images/logo.png" className ={classes.logo}
            </div>

            <div className={classes.center}>
            <div style={{ margin: '10px' , display:"flex"}} className ={classes.handleNavlink}>            
            <NavMenu>

            <NavLink to='/' activeStyle>
            Home
          </NavLink>
          <NavLink to='/register' activeStyle>
            Form
          </NavLink>
          <NavLink to='/verification' activeStyle>
          Verification      
              </NavLink>
              <NavLink to='/contact' activeStyle>
          Contact Us      
              </NavLink>      
          </NavMenu>
          </div>

    <div className ={classes.handleMenu}>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>
        <NavLink to='/' activeStyle>
            Home
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
        <NavLink to='/register' activeStyle>
            Form
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
        <NavLink to='/verification' activeStyle>
          Verification      
              </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
        <NavLink to='/contact' activeStyle>
          Contact Us      
              </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
        <NavLink to={auth ? "dashboard" : "/login"} activeStyle>  
              </NavLink>     
        </MenuItem>
      </Menu>
    </div>
            </div>
            <div className={classes.right}>
            <Button variant ="outlined" className ={classes.loginStyle}><NavLink to={auth ? "dashboard" : "/login"}>Login</NavLink></Button>
                
           
               

            </div> 
            </div>
        
        </div>
        
        </> */}
        </>
    )
}

export default NavBar
