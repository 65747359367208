import axios from "axios";


//private URL
const API = axios.create({
  baseURL: "http://localhost:8000",
});


API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `${
      JSON.parse(localStorage.getItem("profile")).accessToken
    }`;
  } else {
    req.headers.bypass = "IwyQTAQu3CRZy8U5B1pSIC7IKH82";
  }
  // }
  return req;
});
/*--------------------------------------------------------------login*/
export const signIn =(data)=>API.post('/user/signin', data)

/*-----------------------------------------------------------------document*/
export const getDocument=(aadhar_card)=>API.get(`/document/${aadhar_card}`)
export const documentUpload=(data)=>API.post('/document/upload', data)