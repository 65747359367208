import React from "react";
const  image ="/images/404.jpg";

function NotFound() {
  return (
    <div>
      <img src={image} alt="" style={{ width: "70vw", height: "85vh" }} />
    </div>
  );
}

export default NotFound;
