import {makeStyles} from '@material-ui/core';

export const courseStyles = makeStyles((theme)=>({
    container:{
        // width:"100%",
        height:"auto",
        padding:"10px 15px",
    },
   
    courses:{
        height:"100%",
        padding:"10px 15px",
        display:"flex",
        flexWrap:"wrap",
        [theme.breakpoints.down("sm")]:{
            display:"flex",
            justifyContent:"center"
        }

    },
    text:{
        display:"flex",
        // flexWrap:"wrap"
    },
    heading:{
        display:"flex",
        [theme.breakpoints.down("sm")]:{
           fontSize:25,       
        }
        
    },
    wrapper:{
        width:"320px",
        display:"flex",
        alignItems:"center",
        flexDirection: "column",
        

    },
    logo:{
        border: "1px solid #867b7b",
        borderRadius: "50%",
        boxSizing:"border-box",
        height: "80px !important",
        padding: "26px 0",
        textAlign: "center",
        width: "80px",
        display:"flex",
        justifyContent:"center",
        transition: "all 0.5s ease 0s",
        fontSize: "22px !important",
        color: "#19263b !important"
    },
    desc:{
        fontFamily:"Arial, sans-serif",
        // height:"80%",
        width:"80%",
        wordWrap: "break-word",
        fontSize: "13px",
        lineHeight: "22px",
        marginTop:"5px",
        margin:" 0 !important",
        padding: "0 !important",
        textAlign: "center",
        [theme.breakpoints.down("sm")]:{
            textAlign: "justify",
            wordSpacing:"-1px"


        }

    }
    
}))