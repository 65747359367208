import React, { useState , useRef } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import {makeStyles} from '@material-ui/core';

import {
  Toolbar,
  Box,
  TextField,
  Button,
  Grid,
  Paper,
  Container,
  Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { logout } from "../actions/user";
import { uploadDocument } from "../actions/document";
import { useDispatch, useSelector } from "react-redux";
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebase";
import Header from "./header"
import Loader from "../component/common/loader";
const useStyles =makeStyles((theme)=>({

  container:{
      margin:"auto",
      width:"500px",
      backgroundColor:"whitesmoke",
      borderRadius:"10px",
      padding:"50px 15px",
      marginTop:"30px",
      boxShadow: "0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1)"
  },
  wrapper:{
      width:"450px",
      padding:"10px 2px",
  }
}))

const mdTheme = createTheme();

function DashboardContent() {
  const {loader} = useSelector(({document})=>document)
  const classes = useStyles()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [file, setFile] = useState(null);
  const [per, setPer] = useState(null)

  const [data, setData] = useState({
    certificate_no: "",
  });
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };



  const handleUpload=()=>{
    if (file === null) return;
    const name = new Date().getTime() + file.name
    const fileRef = storageRef(
      storage,
      name
    );
    const uploadTask = uploadBytesResumable(fileRef, file);
    uploadTask.on('state_changed', 
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        setPer(progress)
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      }, 
      (error) => {
        console.log(error)
      }, 
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setData((prev)=>({...prev, img:downloadURL}))
        });
      }
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(data.certificate_no && data.img){
      const uploadData={
        certificate_no:data.certificate_no,
        certificate:data.img
      }
      dispatch(uploadDocument(uploadData, navigate));
      clear(); 
    }
   
  };

  const clear =()=>{
    setData({
      certificate_no:"",
    })
  }
  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
      <Header />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* Chart */}
              <Grid item xs={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    position:"absolute",
                    left:"30%",
                    flexDirection: 'column',
                    height: "400px",
                    width:"600px"
                  }}
                >
                  <Grid
                    container
                    justify="center"
                    spacing={1}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Grid item md={6}>
                      <Grid item container spacing={3} justify="center">
                      <Typography variant ="h5" style={{marginTop:20}}>Upload Document</Typography>

                        <Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop:10}}>
                          <TextField
                            type="text"
                            required
                            label="Enter Certificate Number"
                            variant="outlined"
                            fullWidth
                            style={{width:"300px"}}
                            name="certificate_no"
                            value={data.certificate_no}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TextField
                          variant ="outlined"
                          fullWidth
                          name="file"
                          type="file"
                          required
                          // disabled = {true}
                          // onClick={handleDocUpload}
                          label="Upload Document"
                          onChange={(e)=>setFile(e.target.files[0])}
                          InputLabelProps={{ shrink: true }} 
                          // value={file? file.name:"Upload Document"}
                          style={{width:"300px"}}
                          />                        
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                         <Button variant ="contained"
                        disabled={per!== null && per<100}
                        onClick={handleUpload}
                          >
                          {per!== null && per<100 ? "Uploading...": 'Upload'}
                         </Button>
                        
                        </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Button
                        disabled={per!== null && per<100}
                        variant="contained"
                        color="primary"
                        type="Submit"
                        onClick={handleSubmit}
                        // className={classes.button}
                      >
                      {loader ? <Loader />:"Submit"}
                      </Button>
                      </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
                </Grid>
                  </Grid>     
               </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
