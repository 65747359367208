import { IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { Toolbar, Divider, makeStyles, TextField } from "@material-ui/core";


const useStyles = makeStyles(() => ({
  title: {
    flexGrow: 1,
  },
  tagline: {
    justifyContent: "center",
    fontSize: 15,
    color:"#fff",
    backgroundColor:"black",
    // textTransform: "uppercase",
    // fontFamily: "montserrat",
  },
}));
const Footer = () => {
  const classes = useStyles();

  return (
    <>
      <Toolbar >
        <IconButton color="inherit" >{/* <MenuIcon /> */}</IconButton>
      </Toolbar>
      <Divider />

      <Toolbar className={classes.tagline}>All Rights Reserved.© 2023 AccountExpress</Toolbar>
    </>
  );
};

export default Footer;
