import {courseStyles} from '../styles/courses';
import {images} from '../data/data';
import { Typography, Grid } from '@material-ui/core';
const Courses =()=>{
    const classes = courseStyles()
    return(
        <>
        <div className ={classes.container}>
        <Typography variant ="h4" style={{textAlign:"center"}}>Our Courses</Typography>
        <div className ={classes.courses}>
            {
                images.map((val)=>{
                    return (
                        <div className ={classes.wrapper}>

                        <div className ={classes.logo}>{val.logo}</div>
                        <div className={classes.text}>

                        <Typography variant ="h5" className={classes.heading}>{val.text}</Typography>
                                    </div>
                                    <div className={classes.desc}>
                                    <Typography style={{marginTop:2}}>{val.description}</Typography>
                                    </div>
                                    </div>
                    )
                })
            }
        </div>        
        </div>
        </>
    )
}

export default Courses