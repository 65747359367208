import { ACTION_TYPES } from "../constants";
const initialState ={
    students:[],
    getAllData:[],
    loader:false
}

export const registerForm =(state = initialState, action)=>{
    switch(action.type){
        case ACTION_TYPES.FORM_REGISTRATION_REQUEST:
            return {...state, loader:true}
        case ACTION_TYPES.FORM_REGISTRATION_SUCCESS:
            return {...state, students:action.payload, loader:false}
        case ACTION_TYPES.FORM_REGISTRATION_FAILED:
            return {...state, loader:false}
            case ACTION_TYPES.FETCH_FORM_REQUEST:
                return {...state, loader:true}
            case ACTION_TYPES.FETCH_FORM_SUCCESS:
                return {...state, getAllData:action.payload, loader:false}
            case ACTION_TYPES.FETCH_FORM_FAILED:
                return {...state, loader:false}
        default:
           return state;
    }
}