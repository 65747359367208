import { ACTION_TYPES } from "../constants";
import {addDoc, collection, doc, getDocs} from "firebase/firestore"
import {db} from '../firebase/firebase';
import swal from "sweetalert";

export const formRegister =(res)=>async(dispatch, getState)=>{
    try{
        dispatch({type:ACTION_TYPES.FORM_REGISTRATION_REQUEST})
        const data = await addDoc(collection(db, "registration"),{
            firstName:res.firstName,
            lastName:res.lastName,
            email:res.email,
            DOB:res.dob,
            address:res.address,
            phoneNumber:res.phoneNumber,
            createdAt:new Date().getTime()
        })
        dispatch({type:ACTION_TYPES.FORM_REGISTRATION_SUCCESS, payload:data})
        swal({
            title:"Success",
            text:"Form submitted successfully",
            icon:"success",                
        })
    }
    catch(err){
        console.log(err)
        dispatch({type:ACTION_TYPES.FORM_REGISTRATION_FAILED})
        swal({
            title:"Error",
            text:"invalid details",
            icon:"error",
             
        })
    }

}

export const getFormDetails =(res)=>async(dispatch, getState)=>{
    try{
        const data =[]
        dispatch({type:ACTION_TYPES.FETCH_FORM_REQUEST})
        const querySnapshot = await getDocs(collection(db, "registration"));
        querySnapshot.forEach((doc) => {
            data.push(doc.data())
        });
        dispatch({type:ACTION_TYPES.FETCH_FORM_SUCCESS, payload:data})       
    }
    catch(err){
        console.log(err)
        dispatch({type:ACTION_TYPES.FETCH_FORM_FAILED})
    }

}