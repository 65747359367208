export const ACTION_TYPES ={
    DOCUMENT_UPLOAD_REQUEST:"DOCUMENT_UPLOAD_REQUEST",
    DOCUMENT_UPLOAD_SUCCESS:"DOCUMENT_UPLOAD_SUCCESS",
    DOCUMENT_UPLOAD_FAILED:"DOCUMENT_UPLOAD_FAILED",
    DOCUMENT_FETCH_REQUEST:"DOCUMENT_FETCH_REQUEST",
    DOCUMENT_FETCH_SUCCESS:"DOCUMENT_FETCH_SUCCESS",
    DOCUMENT_FETCH_FAILED:"DOCUMENT_FETCH_FAILED",
    AUTH_REQUEST:"LOGIN_REQUEST",
    AUTH_SUCCESS:"LOGIN_SUCCESS",
    AUTH_FAILED:"LOGIN_FAILED",
    REGISTER_REQUEST:"REGISTER_REQUEST",
    REGISTER_SUCCESS:"REGISTER_SUCCESS",
    REGISTER_FAILED:"REGISTER_FAILED",
    CONTACT_REQUEST:"CONTACT_REQUEST",
    CONTACT_SUCCESS:"CONTACT_SUCCESS",
    CONTACT_FAILED:"CONTACT_FAILED",
    FORM_REGISTRATION_REQUEST:"FORM_REQUEST",
    FORM_REGISTRATION_SUCCESS:"FORM_REGISTRATION_SUCCESS",
    FORM_REGISTRATION_FAILED:"FORM_REGISTRATION_FAILED",
    FETCH_FORM_REQUEST:"FETCH_FORMDATA_REQUEST",
    FETCH_FORM_SUCCESS:"FETCH_FORM_SUCCESS",
    FETCH_FORM_FAILED:"FETCH_FORM_FAILED",
    FETCH_CONTACT_REQUEST:"FETCH_CONTACT_REQUEST",
    FETCH_CONTACT_SUCCESS:"FETCH_CONTACT_SUCCESS",
    FETCH_CONTACT_FAILED:"FETCH_CONTACT_FAILED",
    FETCH_DOCUMENT_REQUEST:"FETCH_DOCUMENT_REQUEST",
    FETCH_DOCUMENT_SUCCESS:"FETCH_DOCUMENT_SUCCESS",
    FETCH_DOCUMENT_FAILED:"FETCH_DOCUMENT_FAILED",

    SET_DOCUMENTID_REQUEST:"SET_DOCUMENTID_REQUEST",
    SET_DOCUMENTID_SUCCESS:"SET_DOCUMENTID_SUCCESS",
    SET_DOCUMENTID_FAILED:"SET_DOCUMENTID_FAILED",

    DELETE_DOCUMENT_REQUEST:"DELETE_DOCUMENT_REQUEST",
    DELETE_DOCUMENT_SUCCESS:"DELETE_DOCUMENT_SUCCESS",
    DELETE_DOCUMENT_FAILED:"DELETE_DOCUMENT_FAILED",
    
}