import { ACTION_TYPES } from "../constants";
import {addDoc, collection, getDocs} from "firebase/firestore"
import {db} from '../firebase/firebase';
import swal from "sweetalert";

export const createContact =(res)=>async(dispatch, getState)=>{
    try{
        dispatch({type:ACTION_TYPES.CONTACT_REQUEST})
        const data = await addDoc(collection(db, "contact"),{
            name:res.name,
            email:res.email,
            message:res.msg,
            createdAt:new Date().getTime()
        })
        dispatch({type:ACTION_TYPES.CONTACT_SUCCESS, payload:data})
        swal({
            title:"Success",
            text:"message send successfully",
            icon:"success",                
        })
    }
    catch(err){
        console.log(err)
        dispatch({type:ACTION_TYPES.CONTACT_FAILED})
        swal({
            title:"Error",
            text:"invalid details",
            icon:"error",
             
        })
    }

}
export const getContactDetails =(res)=>async(dispatch, getState)=>{
    try{
        const data =[]
        dispatch({type:ACTION_TYPES.FETCH_CONTACT_REQUEST})
        const querySnapshot = await getDocs(collection(db, "contact"));
        querySnapshot.forEach((doc) => {
            data.push(doc.data())
        });
        dispatch({type:ACTION_TYPES.FETCH_CONTACT_SUCCESS, payload:data})       
    }
    catch(err){
        console.log(err)
        dispatch({type:ACTION_TYPES.FETCH_CONTACT_FAILED})
    }

}