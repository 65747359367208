import React, {useState, useEffect} from 'react'
import NavBar from "./navBar"
import { TextField , Grid, Box, Button, Container, CssBaseline, makeStyles} from "@material-ui/core";
import { VerificationStyles } from "../styles/verifi";
import { createTheme, ThemeProvider } from "@mui/material/styles"
import {useDispatch, useSelector} from 'react-redux';
import {db} from '../firebase/firebase';
import Loader from "./common/loader";
import {collection, getDocs} from "firebase/firestore";
import swal from "sweetalert"

const Verification =()=>{
  const classes = VerificationStyles()
  const theme = createTheme()
  const dispatch = useDispatch()
    const [data, setData] = useState({
      certificate_no:""
    })
    const [loader, setLoader]= useState(false)
    const [value, setValue] = useState("")
    const handleChange=(e)=>{const {name, value}=e.target
        setData({...data,[name]:value})       
          }

    const handleSubmit =async(e)=>{
        e.preventDefault()
        setLoader(true)
      try{
        const querySnapshot = await getDocs(collection(db, "document"));
            querySnapshot.forEach((doc) => {
              const v = doc.data().certificateNo === data.certificate_no
              // doc.data() is never undefined for query doc snapshots
              if(v){
                setValue(doc.data().certificate)
              }
              });
              setLoader(false)
          }
          catch(err){ 
            console.log(err)
          }
    }
    return (
        <>
          <NavBar/>
          <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs" style={{backgroundColor:"whitesmoke", borderRadius:"15px", marginTop:"10px", paddingBottom:"10px"}}>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >           
          <Grid container>
            <Grid item xs={12}>
          <TextField
              margin="normal"
              required
              id="numver"
              style={{width:"300px"}}
              label="Certificate Number"
              name="certificate_no"
              autoComplete="off"
              value={data.certificate_no}
              onChange={handleChange}
              autoFocus
            />
           </Grid>
           <Grid item xs={12} lg={12}>
            <Button
              type="submit"
              variant="contained"
              style={{backgroundColor:"#120F11", color:"#fff"}}
              onClick={handleSubmit}
            >
              {loader ? <Loader />:"Submit"}
            </Button>
           </Grid>
           </Grid>
          </Box>
          </Container>
          </ThemeProvider>
              
           {value ?
              <div style={{marginTop:"40px",display:"flex", justifyContent:"center"}}>
             <img src={value} alt="image" className={classes.doc} /> 
            </div>:""}
                      
        </>
    )
}

export default Verification