import { ACTION_TYPES } from "../constants";
const initialState ={
    allData:[],
    document:{},
    allDocs:[],
    documentId:{},
    loader:false
}

export const document=(state=initialState, action)=>{

    switch(action.type){
        case ACTION_TYPES.DOCUMENT_FETCH_REQUEST:
            return{...state, loader:true}
        case ACTION_TYPES.DOCUMENT_FETCH_SUCCESS:
            return {...state, document:action.payload, loader:false}
        case ACTION_TYPES.DOCUMENT_FETCH_FAILED:
            return {...state, loader:false}
        case ACTION_TYPES.DOCUMENT_UPLOAD_REQUEST:
            return{...state, loader:true}
        case ACTION_TYPES.DOCUMENT_UPLOAD_SUCCESS:
            return {...state, allData:action.payload, loader:false}
        case ACTION_TYPES.DOCUMENT_UPLOAD_FAILED:
            return {...state, loader:false}

        case ACTION_TYPES.FETCH_DOCUMENT_REQUEST:
            return{...state, loader:true}
        case ACTION_TYPES.FETCH_DOCUMENT_SUCCESS:
            return {...state, allDocs:action.payload, loader:false}
        case ACTION_TYPES.FETCH_DOCUMENT_FAILED:
            return {...state, loader:false}

        case ACTION_TYPES.SET_DOCUMENTID_REQUEST:
            return{...state, loader:true}
        case ACTION_TYPES.SET_DOCUMENTID_SUCCESS:
            console.log("redi", {...state, documentId:action.payload, loader:false})
            return {...state, documentId:action.payload, loader:false}
        case ACTION_TYPES.SET_DOCUMENTID_FAILED:
            return {...state, loader:false}
        default:
            return state;
    }
}