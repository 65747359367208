import {ACTION_TYPES} from '../constants';
import {signIn} from "../api";
import {createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile} from 'firebase/auth'
import {addDoc, collection} from "firebase/firestore"
import {auth, db} from '../firebase/firebase';
import swal from "sweetalert";

export const register =(res, navigate)=>async(dispatch, getState)=>{
    try{
        dispatch({type:ACTION_TYPES.REGISTER_REQUEST})
        const data = await createUserWithEmailAndPassword(auth,res.email, res.password)
        const user =  data.user
        await updateProfile(user,{
            displayName:res.name
        })
        await addDoc(collection(db, "users"), {
            name: res.name,
            email: res.email,
            uid:user.uid
          });
        navigate("/login")
        dispatch({type:ACTION_TYPES.REGISTER_SUCCESS, payload:user})
    }
    catch(error){
        console.log(error.message)
        dispatch({type: ACTION_TYPES.REGISTER_FAILED})
    }

}

export const login =(res, navigate)=>async(dispatch, getState)=>{
    try{
        dispatch({type:ACTION_TYPES.AUTH_REQUEST})
        const data = await signInWithEmailAndPassword(auth,res.userName, res.password)
            dispatch({type:ACTION_TYPES.AUTH_SUCCESS,  payload: {
                firebaseId: data.user.uid,
                email: data.user.email,
                token: data._tokenResponse.refreshToken,
                accessToken: data.user.accessToken,
                lastLoginAt: data.user.metadata.lastLoginAt,
              }}) 
            swal({
                title:"Success",
                text:"Login Sucess",
                icon:"success",                
            })
            navigate("/dashboard")
    }
    catch(error){
        console.log(error.message)
        swal({
            title:"Error",
            text:error.message,
            icon:"error",
             
        })
        dispatch({type:ACTION_TYPES.AUTH_FAILED})
    }

}

export  const logout =(res)=>(dispatch, getState)=>{
    try{
        dispatch({type:"LOGOUT"})
    }
    catch(error){
        console.log(error.message)
    }
}