import { makeStyles } from "@material-ui/core";

export const VerificationStyles =makeStyles((theme)=>({
container:{
    height:100,
    width:"50%"
},
wrapper:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    border:"1px solid",
    width:"100%",
    padding:"10px 15px"
},

doc:{
    height:500,
    width:500,
    [theme.breakpoints.down("md")]:{
        width:300,
        height:350
    }
}

}))

export const formStyles = makeStyles((theme)=>({
    container:{
        margin:"auto",
        width:"500px",
        borderRadius:"10px",
        padding:"20px 15px",
        marginTop:"10px",
        backgroundColor:"whitesmoke",
        boxShadow: "0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1)"
    },
    wrapper:{
        width:"450px",
        padding:"10px 15px",
    },
    inputField:{
        width:"95%",
        margin:7
    },
    inputFieldF:{
        width:"90%",
        margin:7
    },
    heading:{
        display:"flex",
        justifyContent:"center",
        paddingTop:"10px"

    },
    button:{
        margin: 10,
        width: 150,
        height: 45,
        backgroundColor:"#120F11"   ,
        color:"#fff"
     },
    showError:{
        marginLeft:10
    },
    error:{
        color:"red",
       
    },
}))