import { applyMiddleware, createStore } from "redux";
import reduxThunk from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {reducer} from "../reducer";
import whitelist from "./whitelist";

const persistConfig = {
  timeout: 90000,
  whitelist,
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(
  persistedReducer,
  undefined,
  applyMiddleware(reduxThunk)
);

export const persistor = persistStore(store, {}, () => {});
