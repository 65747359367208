import {sliderStyles} from '../styles/slider';
import { Typography, Box } from "@material-ui/core";
import Carousel from "react-material-ui-carousel";
// import {img, img3} from "assets"
const img ='/images/account.jpg';
const img2 ='/images/account1.jpg'
const images =[img, img2]
const Image =({image})=>{
    const classes = sliderStyles()
    return(
        <>
        <div className ={classes.container}>
        <img src ={image} alt =""  className={classes.frontImage}/>
        </div>

        </>
    )
}

const Slider =()=>{
    return(
        <>
        <Carousel
            navButtonsAlwaysInvisible={true}
            indicators={false}
            autoPlay={true}
            timeout={100}
            >
            {images &&
                images.length &&
                images.map((image) => <Image key={Math.random()} image={image} />)}
            </Carousel>
        </>
    )
}

export default Slider