import { ACTION_TYPES } from "../constants";
import { addDoc, collection, getDocs, doc, deleteDoc } from "firebase/firestore"
import { db } from '../firebase/firebase';
import swal from "sweetalert";


export const uploadDocument =(res, navigate)=>async(dispatch, getState)=>{
    try{
        const {allData} = getState().document
        dispatch({type:ACTION_TYPES.DOCUMENT_UPLOAD_REQUEST})
        const data = await addDoc(collection(db, "document"),{
           certificateNo: res.certificate_no,
           certificate: res.certificate,
           createdAt: new Date().getTime()
        })
        dispatch({type:ACTION_TYPES.DOCUMENT_UPLOAD_SUCCESS, payload:[...allData, data]})
        swal({
            title:"Success",
            text:"Data saved successfully",
            icon:"success",                
        })
        navigate("/document")
    }
    catch(error){
        console.log(error.message)
        dispatch({type:ACTION_TYPES.DOCUMENT_UPLOAD_FAILED})
        swal({
            title:"Error",
            text:"Document upload error",
            icon:"error",
             
        })
    }
}

export const fetchDocument =(res)=>async(dispatch, getState)=>{
    try{
        const data =[]
        dispatch({type:ACTION_TYPES.FETCH_DOCUMENT_REQUEST})
        const querySnapshot = await getDocs(collection(db, "document"));
        querySnapshot.forEach((doc) => {
            data.push({docId: doc.id, data:doc.data()})
        });
        dispatch({type:ACTION_TYPES.FETCH_DOCUMENT_SUCCESS, payload:data})       
    }
    catch(err){
        console.log(err)
        dispatch({type:ACTION_TYPES.FETCH_DOCUMENT_FAILED})
    }
}

export const setDocumentid =(res)=>async(dispatch, getState)=>{
    try{
        dispatch({type:ACTION_TYPES.SET_DOCUMENTID_REQUEST})
        console.log(res)
        dispatch({type:ACTION_TYPES.SET_DOCUMENTID_SUCCESS, payload:res})
    }
    
    catch(err){
        console.log(err)
        dispatch({type:ACTION_TYPES.SET_DOCUMENTID_FAILED})
    }
}

export const deleteDocument =(id)=>async(dispatch, getState)=>{
    try{
        dispatch({type:ACTION_TYPES.DELETE_DOCUMENT_REQUEST})
        const docRef = doc(db, "document", id)
        await deleteDoc(docRef)
        dispatch({type:ACTION_TYPES.DELETE_DOCUMENT_SUCCESS})
        dispatch(fetchDocument())
    }
    catch(err){
        console.log(err)
        dispatch({type:ACTION_TYPES.DELETE_DOCUMENT_FAILED})
    }
}
