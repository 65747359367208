import { Grid, Typography } from "@material-ui/core";

import { navStyles } from "../styles/navBar";
const About = () => {
  const classes = navStyles();
  return (
    <>
      <div className={classes.about}>
          <Grid container>
            <Grid lg={12} md={12} sm={12} xs={12}>
                <Typography variant ="h4" style={{textAlign:"center"}}>About Us</Typography>
             <Typography style={{
               wordWrap: "break-word",
               fontSize: "16px",
               fontFamily:"Arial, sans-serif",
               lineHeight: "22px",
               margin:" 0 !important",
               padding: "0 !important",
               textAlign: "justify",
               wordSpacing:"-1px"

              }}> The accounts expess is a  academy based in Malout dedicated to providing
               remarkable education The accounts expess- Professionals in Accounting Services related 
               to Accounting, Taxation, , Materials Management etc, 
               The procedure for recording company financial transactions in an accounting system and
                the creation of reports is known as bookkeeping.  we believe in providing timely and
                 quality services to our clients that help aspiring students to succeed in the most 
                 dynamic realms of the industry. Our coherent training system coupled with the 
                 expertise of our faculty and hi-end technologies makes us capable of molding 
                 students and unlock international careers with high-degree professionalism.thereby, 
                 shifting the compliance load from your shoulders to ours.</Typography>
            </Grid>
          </Grid>
        </div>
    </>
  );
};

export default About;
