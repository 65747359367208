import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import UploadIcon from '@mui/icons-material/Upload';
import AssignmentIcon from '@mui/icons-material/Assignment';
import {Link} from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';

const style={
  textDecoration:"none",
  color:"gray"
}

export const mainListItems = (
  <React.Fragment>
        <Link to ="/dashboard" style={style}>
    <ListItemButton >
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    </Link>
    <Link to ="/contactDetails" style={style}>
    <ListItemButton>
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary="Contact Details" />
   
    </ListItemButton>
    </Link>
    <Link to ="/upload" style={style}>
    <ListItemButton>
      <ListItemIcon>
        <UploadIcon />
      </ListItemIcon>
      <ListItemText primary="Upload a document" />
   
    </ListItemButton>
    </Link>
    <Link to ="/document" style={style}>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Documents" />
   
    </ListItemButton>
    </Link>
  </React.Fragment>
);

