import { ACTION_TYPES } from "../constants";
const initialState ={
    contact:[],
    contactForm:[],
    loader:false
}

export const contact =(state = initialState, action)=>{
    switch(action.type){
        case ACTION_TYPES.CONTACT_REQUEST:
            return {...state, loader:true}
        case ACTION_TYPES.CONTACT_SUCCESS:
            return {...state, contact:action.payload, loader:false}
        case ACTION_TYPES.CONTACT_FAILED:
            return {...state, loader:false}

            case ACTION_TYPES.FETCH_CONTACT_REQUEST:
                return {...state, loader:true}
            case ACTION_TYPES.FETCH_CONTACT_SUCCESS:
                return {...state, contactForm:action.payload, loader:false}
            case ACTION_TYPES.FETCH_CONTACT_FAILED:
                return {...state, loader:false}
        default:
           return state;
    }
}