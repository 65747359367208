import { makeStyles} from "@material-ui/core";

export const sliderStyles =makeStyles((theme)=>({
    container:{
        // width:"100%",
        // height:"100vh",
        // marginTop:"20px"
    },
    frontImage : {
        width: '100%',
        height: '70vh',
        position : 'relative',
        backgroundSize : 'cover',

    },
}))

