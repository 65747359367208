import { ACTION_TYPES } from "../constants";
const initialState ={
    user:{},
    users:[],
    loader:false,
    isLoggedIn:false
}

export const user =(state= initialState, action)=>{
    switch(action.type){
        case ACTION_TYPES.AUTH_REQUEST:
            return {...state, loader:true}
        case ACTION_TYPES.AUTH_SUCCESS:
            localStorage.setItem("profile",JSON.stringify(action.payload));
            return {...state, user:action.payload, loader:false, isLoggedIn:true}
        case ACTION_TYPES.AUTH_FAILED:
            return {...state, loader:false, isLoggedIn:false, user:{}}
            case ACTION_TYPES.REGISTER_REQUEST:
                return {...state, loader:true}
            case ACTION_TYPES.REGISTER_SUCCESS:
                return {...state, users:action.paylaod, loader:false}
            case ACTION_TYPES.REGISTER_FAILED:
                return {...state, loader:false}
            case "LOGOUT":
                localStorage.removeItem("profile");
                return {...state, isLoggedIn:false, user:{}};
            default:
                return state;
    }

}