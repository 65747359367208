import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { useState } from "react";
import {login} from '../actions/user';
import Loader from '../component/common/loader'

const SignIn = ({setAuth}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {loader, user} = useSelector(({user})=>user)
  const auth = user.accessToken
  const theme = createTheme();
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  // const [error, setError] = useState("")
  // const [loading, setLoading] = useState(false)

  useEffect(()=>{
    auth && navigate('/dashboard')
},[auth])

  const Data = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email, password } = data;
    if (email === "") {
      alert("username is required");
    } 
     else if (password === "") {
      alert("password is required");
    } else {
      const values ={
        userName:data.email,
        password:data.password
      }
      await dispatch(login(values, navigate, setAuth));
      clear()
    }
  };
  const clear =()=>{
    setData({
      email:"",
      password:""
    })
  }
  return (
    <>   
  <Button variant="outlined" onClick={()=>navigate("/")}>Back</Button>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src="/images/logo.png" height="100" width="130"/>
            {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}> */}
              {/* <LockOutlinedIcon /> */}
            {/* </Avatar> */}
            {/* {
            error?<Alert variant = 'danger'>{error}</Alert>:null
          } */}
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>

            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Username"
                name="email"
                value={data.email}
                onChange={Data}
                autoComplete="off"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={data.password}
                onChange={Data}
                autoComplete="off"
              />
            
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                style={{backgroundColor:"#120F11", color:"#fff"}}
                sx={{ mt: 3, mb: 2 }}
              >
                {loader ? <Loader size={25}/>:"Login"}
              </Button>
            </Box>
          </Box>

          {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
        </Container>
      </ThemeProvider>
    </>
  );
};

export default SignIn;
